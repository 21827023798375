import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ContentBody = styled.div`
  p,
  ul,
  ol {
    color: #2d3748;
    text-align: justify;
    margin-bottom: 10px;
  }
  h1,
  h2,
  h3,
  h4 {
    font-weight: bold;
    margin-bottom: 5px;
    color: #2a4365;
    text-transform: uppercase;
  }
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 1.875rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  ul {
    list-style-type: disc;
    padding: 0 0 0 1em;
  }
  ol {
    list-style-type: decimal;
    padding: 0 0 0 1em;
  }
  img {
    margin: 5px;
  }
`;

interface Props {}

interface State {}

class PageBody extends React.Component<Props, State> {
  public render() {
    return (
      <ContentBody className="text-md md:text-lg lg-text-xl ">
        {this.props.children}
      </ContentBody>
    );
  }
}

export default PageBody;
