import React from "react";
import { graphql } from "gatsby";

import PageBody from "../components/ui/pageBody";
import Container from "../components/ui/container";
import Section from "../components/ui/section";
import BasicLayout from "../layouts/BasicLayout";
import PageHeader from "../components/ui/pageHeader";
import { MDXRenderer } from "gatsby-plugin-mdx";

class Page extends React.Component {
  render() {
    const page = this.props.data.page;
    return (
      <div>
        <BasicLayout pageTitle={page.frontmatter.title}>
          <PageHeader
            title={page.frontmatter.title}
            image={page.frontmatter.image}
            breadcrumb={[
              { page: page.frontmatter.title, path: this.props.uri },
            ]}
          />
          <Section background="bg-white">
            <Container padding>
              <PageBody>
                <MDXRenderer>{page.body}</MDXRenderer>
              </PageBody>
            </Container>
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Page;

export const PageQuery = graphql`
  query AboutQuery {
    page: mdx(frontmatter: { id: { eq: "about" } }) {
      frontmatter {
        title
        image
      }
      body
    }
  }
`;
